import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [send, setSend] = useState(null); // Initialize send state as null

  const SendEmail = async () => {
    try {
      // Validate form fields
      if (!name || !email || !service || !message) {
        toast.error("Please fill in all fields");
        return;
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("service", service);
      formData.append("message", message);
      console.log("hahahhahah", formData);

      const response = await axios.post(
        "http://localhost:4000/api/contactus",
        // "https://remcosol.com/contactus",

        formData,
        config
      );

      // Handle the response or set the send state as needed
      setSend(response.data);

      // Reset form fields
      setName("");
      setEmail("");
      setService("");
      setMessage("");
      // Display a success message
      toast.success("Contact Form Submitted");
    } catch (error) {
      // Handle errors
      console.error(error);
      toast.error("An error occurred while submitting the form.");
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();

    SendEmail();
  };

  return (
    <>
      <Box id="contactSection" backgroundColor="white">
        <Grid
          container
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          paddingLeft= "10%"
              paddingRight= "10%"
          sx={{
            "@media(max-width:480px)": {

              paddingTop: "0rem",
            },
            "@media (min-width:481px) and (max-width:769px)": {

              paddingTop: "0rem",
            },
            "@media (min-width:769px) and (max-width:1024px)": {
              paddingLeft: "0%",
              paddingRight: "0%",
              paddingTop: "0rem",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            },
          }}
        >
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box
              sx={{
                color: "#261141",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: "2rem",
                  fontWeight: "600",

                  color: "#261141",

                  "@media(max-width:480px)": {
                    fontSize: "1.5rem",
                    paddingTop: "0rem",

                  },
                  "@media(min-width:480px) and (max-width:768px)": {
                    fontSize: "1rem",

                  },
                  "@media(min-width:769px) and (max-width:1024px)": {
                    fontSize: "1rem",

                  },
                }}
              >
                Contact us &rarr;
              </Typography>

              <Typography
                sx={{
                  paddingTop: "1rem",

                  color: "black",
                  "@media(max-width:480px)": {
                    fontSize: "0.9rem",

                  },
                  "@media(min-width:480px) and (max-width:768px)": {
                    fontSize: "0.7rem",

                  },
                  "@media(min-width:769px) and (max-width:1024px)": {
                    fontSize: "0.5rem",

                  },
                }}
              >
                Committed to excellence, we focus on delivering high-quality
                solutions. Your satisfaction drives our dedication to continuous
                improvement
              </Typography>
              <br />
              <Box display={"flex"}



              >
                <PhoneIcon
                  sx={{
                    color: "black",
                    "@media(max-width:480px)": {
                      fontSize: "1rem",

                    },
                  }}
                />

                <Typography
  sx={{
    color:'black',
    '@media(max-width:480px)': {
      fontSize: '0.7rem',
    },
    cursor: 'pointer', // Add this to change the cursor to a pointer
  }}
  onClick={() => window.location.href = 'tel:+923130880216'}
>
  +92 313 0880216
</Typography>
              </Box>

              <Box display={"flex"} paddingTop={"1rem"}


              >
                <LocationOnIcon
                  sx={{
                    color: "black",
                    "@media(max-width:480px)": {
                      fontSize: "1rem",
                    },
                  }}
                />
             <Typography
  sx={{
    color: "black",
    '@media(max-width:480px)': {
      fontSize: '0.7rem',
    },
    cursor: 'pointer', // Add this to change the cursor to a pointer
  }}

>
  Lahore, Pakistan
</Typography>

              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <Box
              sx={{

                paddingTop: "3rem",
                paddingBottom: "3rem",
                display: "flex",


                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",

                minHeight: "30rem",
                // boxShadow:'1px 1px 9px #261141',

                "@media(max-width:600px)": {
                  marginLeft: "0rem",
                  minHeight: "20rem",
                  width: "100%",
                  boxShadow: "none",
                },
                "@media(min-width:600px) and (max-width:900px)": {
                  marginLeft: "0",
                  marginTop: "2rem",
                  minHeight: "20rem",
                },
              }}
            >
              <form
                style={{
                  width: "100%",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onSubmit={submitHandler}
              >
                <TextField
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  style={{
                    width: "100%",


                    marginBottom: "1.5rem",
                  }}
                  name="name" // Add 'name' attribute for form field identification
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  id="standard-basic"
                  label="Email"
                  variant="standard"
                  style={{
                    width: "100%",
                    marginBottom: "1.5rem",
                  }}
                  name="email" // Add 'name' attribute for form field identification
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  id="standard-basic"
                  label="Service You Want"
                  variant="standard"
                  style={{
                    width: "100%",
                    marginBottom: "1.5rem",
                  }}
                  name="service" // Add 'name' attribute for form field identification
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                />
                <TextField
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={4}
                  variant="standard"
                  style={{
                    marginBottom: "1.5rem",
                    width: "100%",
                  }}
                  name="message" // Add 'name' attribute for form field identification
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#261141",
                    borderRadius: "12px",
                    fontSize: "1.2rem",
                    alignSelf: "center",
                    "&:hover": {
                      backgroundColor: "#40b298",
                      transition: "background-color 0.3s ease-in-out",
                      color: "white",
                    },
                  }}
                >
                  Submit
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactForm;

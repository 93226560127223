import React, {useState} from 'react'
// import Slider from "react-slick";
import ReactIcon from "../../Assets/Images/Technologies/React.svg";
import Python from "../../Assets/Images/Technologies/Python.svg";
import IOS from "../../Assets/Images/Technologies/iOS.svg";
import Postgre from "../../Assets/Images/Technologies/postgre.svg";
import SQL from "../../Assets/Images/Technologies/MySQL.svg";
import Azure from "../../Assets/Images/Technologies/MSAzure.svg";
import FastAPI from '../../Assets/Images/Technologies/fastapi.svg';
import Node from '../../Assets/Images/Technologies/NodeJS.svg';
// import Android from '../../Assets/Images/Technologies/android.png';
// import Pytorch from '../../Assets/Images/Technologies/pytorch.png';
import TensorFlow from '../../Assets/Images/Technologies/tensorflow.svg';
// import MUI from '../../Assets/Images/Technologies/MUI.png';




import { Box, Button, Typography } from '@mui/material';

const Expertise = () => {
    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     arrows: false,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     initialSlide: 0,
    //     responsive: [
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 1,
    //           infinite: true,
    //           autoplay: true,

    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 600,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 1,
    //           initialSlide: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //         },
    //       },
    //     ],
    //   };

      const [activeSection, setActiveSection] = useState(1);

      const handleButtonClick = (sectionNumber) => {
        setActiveSection(sectionNumber);
      };


  return (
    <>
         {/* <Box
        sx={{
         paddingTop:'3rem',
         paddingBottom:'5rem',
paddingLeft:'9%',
paddingRight:'9%',
          minHeight:'5rem',
          '@media(min-width:769px)':{
            display:'none'
          }
        }}
      >

<Box sx={{ width:'100%', paddingTop:'1rem', paddingBottom:'1.5rem' }}>

<Typography
          variant="h1"
          sx={{
            fontWeight: "600",
            fontSize: "1.8rem",
            color:'#261141',
            "@media(max-width:480px)": {
              fontSize: "1.3rem",
            },
          }}
          className="line"
        >
          Technologies
        </Typography>

        </Box>

        <Slider {...settings}>
          <Box
            sx={{
              paddingLeft: "6rem",
              paddingRight: "5rem",

            }}
          >


<img src={ReactIcon} style={{backgroundColor:'white', padding:'1rem 0rem 1rem 0rem', borderRadius:'3px'}} height={'50%'} width={'100%'}/>
          </Box>
          <Box
            sx={{
              paddingLeft: "6rem",
              paddingRight: "5rem",
            }}
          >
<img src={Python} style={{backgroundColor:'white', padding:'1rem 0rem 1rem 0rem', borderRadius:'3px'}} height={'50%'} width={'100%'}/>

          </Box>
          <Box
            sx={{
              paddingLeft: "6rem",
              paddingRight: "5rem",
            }}
          >
<img src={Node} style={{backgroundColor:'white', padding:'1rem 0rem 1rem 0rem', borderRadius:'3px'}} height={'40%'} width={'90%'}/>

          </Box>
          <Box
            sx={{
              paddingLeft: "6rem",
              paddingRight: "5rem",
            }}
          >
<img src={Azure} style={{backgroundColor:'white', padding:'1rem 0rem 1rem 0rem', borderRadius:'3px'}} height={'50%'} width={'100%'}/>

          </Box>
          <Box
            sx={{
              paddingLeft: "6rem",
              paddingRight: "5rem",
            }}
          >
<img src={SQL} style={{backgroundColor:'white', padding:'1rem 0rem 1rem 0rem', borderRadius:'3px'}} height={'50%'} width={'100%'}/>

          </Box>
          <Box
            sx={{
              paddingLeft: "6rem",
              paddingRight: "5rem",
            }}
          >
<img src={Postgre} style={{backgroundColor:'white', padding:'1rem 0rem 1rem 0rem', borderRadius:'3px'}} height={'50%'} width={'100%'}/>

          </Box>
        </Slider>
      </Box> */}

      <Box sx={{paddingLeft:'10%', paddingRight:'10%', paddingTop:'4rem',

      backgroundColor:'white'
      ,'@media(max-width:769px)':{
        display:'none'
      }

      }}>
      <Box sx={{ width:'100%', paddingBottom:'3rem' ,



        }}>
 <Typography
          variant="h1"
          sx={{
            fontWeight: "600",
            fontSize: "1.8rem",
            color:'#261141',
            "@media(max-width:480px)": {
              fontSize: "1.2rem",
            },
          }}
          className="line"
        >
          Technologies
        </Typography>

        </Box>
      <div >
        <Button sx={{marginRight:'1rem', fontSize:'1rem', paddingTop:'0.5rem', paddingBottom:'0.5rem', borderRadius:'25px'}}
          variant={activeSection === 1 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick(1)}
        >
          Web Development
        </Button>
        <Button sx={{marginRight:'1rem', fontSize:'1rem', paddingTop:'0.5rem', paddingBottom:'0.5rem', borderRadius:'25px'}}
          variant={activeSection === 2 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick(2)}
        >
          Mobile Development
        </Button>
        <Button
        sx={{ fontSize:'1rem', paddingTop:'0.5rem', paddingBottom:'0.5rem', borderRadius:'25px'}}
          variant={activeSection === 3 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleButtonClick(3)}
        >
          Generative AI
        </Button>
      </div>
      <div style={{marginTop:'0.5rem'}}>
        {activeSection === 1 &&
<Box sx={{minHeight:'5rem', }}>

<img src={ReactIcon} className='imgbg' height={'100%'} width={'12%'} alt='React JS'/>
{/* <img src={MUI} className='imgbg' height={'10%'} width={'12%'}/> */}
<img src={SQL} className='imgbg' height={'100%'} width={'12%'} alt='SQL'/>
<img src={Azure} className='imgbg' height={'100%'} width={'12%'} alt='Azure'/>
<img src={Postgre} className='imgbg' height={'100%'} width={'12%'} alt='PostgreSQL'/>
<img src={Node} className='imgbg' height={'100%'} width={'12%'} alt='Node'/>
<img src={Python} className='imgbg' height={'100%'} width={'12%'} alt='Python'/>




</Box>


        }
        {activeSection === 2 &&
          <Box sx={{minHeight:'15rem', }}>

<img src={ReactIcon} className='imgbg' height={'100%'} width={'12%'} alt='React JS'/>
<img src={IOS} className='imgbg' height={'100%'} width={'12%'} alt='IOS'/>
{/* <img src={Android} className='imgbg' height={'100%'} width={'12%'}/> */}
<img src={Node} className='imgbg' height={'100%'} width={'12%'} alt='Node JS'/>
<img src={Python} className='imgbg' height={'100%'} width={'12%'} alt='Python'/>
<img src={SQL} className='imgbg' height={'100%'} width={'12%'} alt='SQL'/>
<img src={Azure} className='imgbg' height={'100%'} width={'12%'} alt='Azure'/>
<img src={Postgre} className='imgbg' height={'100%'} width={'12%'} alt='PostgreSQL'/>



</Box>
        }
        {activeSection === 3 &&
          <Box sx={{minHeight:'15rem', }}>

<img src={Python} className='imgbg' height={'100%'} width={'12%'} alt='Python'/>
<img src={TensorFlow} className='imgbg' height={'100%'} width={'12%'} alt='Tensorflow'/>
{/* <img src={Pytorch} className='imgbg' height={'100%'} width={'12%'}/> */}
<img src={FastAPI} className='imgbg' height={'100%'} width={'12%'} alt='FAST API'/>





</Box>
        }
      </div>
    </Box>


    </>
  )
}

export default Expertise
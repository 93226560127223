import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ApplyFormModal from "../Career/ApplyFormModal";

const OpenJobs = () => {
  // State variables and functions for each job description
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);
  const [showMore5, setShowMore5] = useState(false);
  const [showMore6, setShowMore6] = useState(false);
  const [showMore7, setShowMore7] = useState(false);



  const text1 = `
  <span style="font-size: 0.9rem;">

  Are you a creative and experienced UI/UX Designer with a keen eye for detail and a passion for crafting exceptional user experiences? We're seeking a talented designer to join our team and help us shape user interfaces that are both visually appealing and highly intuitive.
  <br />
  </span>
  <span style="font-size: 0.9rem; font-weight:650">

  Requirements:<br />
  </span>
  <span style="font-size: 0.8rem;">
  - Bachelor’s degree in Design, computer science or related field.<br />
  - 3 Years of Hands-on experience with Figma, Adobe XD, Sketch.<br />
  - Strong portfolio showcasing your UI/UX design work.<br/>
- Understanding of user-centered design principles.

  </span>
`;

  const text2 = `
  <span style="font-size: 0.9rem;">

  Are you a talented and passionate Frontend Developer with a track record of excellence in web development? We're looking for a experienced frontend developer to join our team and help us create exceptional user experiences. If you have a deep understanding of HTML, CSS, JavaScript, ReactJS, NextJS, Typescript, Redux, and other essential frontend technologies, we want to hear from you.
  </span>
  <br />
  <span style="font-size: 0.9rem; font-weight:650">
  Requirements:<br />
</span>

  <span style="font-size: 0.8rem;">
  - Bachelor's degree in Computer Science, or a related field.<br />
  - Minimum of 3 years of professional experience as a Frontend Developer.<br />
  - Proficiency in HTML, CSS, JavaScript, ReactJS, NextJS, and Redux.<br />
  - Familiarity with RESTful APIs and asynchronous programming.

  </span>
  `;
  const text3 = `
  <span style="font-size: 0.9rem;">

  Are you an experienced Backend Developer with a strong command of NodeJS, ExpressJS, Python, MySQL, MongoDB,  PostgreSQL,  Docker,  AWS, and other backend technologies? If you have a proven track record of designing, implementing, and maintaining robust server-side applications, we want you to join our dynamic team.<br />
</span>
  <span style="font-size: 0.9rem; font-weight:650">
  Requirements:<br />
</span>
  <span style="font-size: 0.8rem;">

  - Bachelor's degree in Computer Science, or a related field. <br />
- A minimum of 3 years of professional experience as a Backend Developer.<br />
- Proficiency in Node.js, Python,  Docker, AWS, and other relevant backend technologies.<br />
- Strong understanding of RESTful APIs, web security, and backend architecture.<br />


  </span>
  `;

  const text4 = `
  <span style="font-size: 0.9rem;">

  Are you an accomplished Full Stack Developer with expertise in a wide range of technologies, including but not limited to HTML, CSS, JavaScript, Node.js, Python, ReactJS, NextJS, Typescript, Redux, MySQL, MongoDB, Express.js, PostgreSQL, Docker, AWS, and more? If you're not only a technical expert but also a Team leader, we want you to lead and inspire our development team.
  <br />
  </span>
  <span style="font-size: 0.9rem; font-weight:650">

  Requirements:<br />
</span>
  <span style="font-size: 0.8rem;">
  - Bachelor's degree in Computer Science or a related field. <br />
- A minimum of 5 years of professional experience as a Full Stack Developer.<br />
- Proficiency in a broad range of technologies, including HTML, CSS, JavaScript, Node.js, Python, ReactJS, NextJS, Redux, Docker, AWS, and more.<br />
- Strong problem-solving and debugging skills.<br />
- Proven leadership skills and experience managing a development team.<br />
- Excellent communication and collaboration abilities.<br />
- Ability to work in a fast-paced, dynamic environment.<br />
- A strong portfolio showcasing your technical and leadership skills.<br />



  </span>
  `;

  const text5 = `
  <span style="font-size: 0.9rem;">

  We're seeking a talented UI/UX Designer Intern at RemcoSol Technologies , you will have the opportunity to gain hands-on experience in designing user interfaces and user experiences. Are you a creative UI/UX Designer with a keen eye for detail and a passion for crafting exceptional user experiences?
  <br />
  </span>
  <span style="font-size: 0.9rem; font-weight:650">

  Requirements:<br />
</span>
  <span style="font-size: 0.8rem;">
  - Enrolled in or recently completed a degree in a related field.<br/>
  - Strong passion for design and an eye for detail.<br/>
  - Proficiency in design tools such as Adobe Creative Suite, Figma, Sketch.<br/>
  - Basic understanding of UI/UX design principles and methodologies.<br/>
  - Excellent communication and teamwork skills.<br/>
  - Ability to take constructive feedback and iterate on designs.<br/>
  - Portfolio showcasing design projects and creativity.




  </span>
  `;

  const text6 = `
  <span style="font-size: 0.9rem;">

  Are you a driven and passionate intern with an emerging interest in Frontend Development using React? If you have a basic understanding of HTML, CSS, JavaScript, ES6, ReactJS and are eager to learn and grow as a Frontend Developer, we invite you to embark on an internship with our dynamic team.
  <br />
  </span>
  <span style="font-size: 0.9rem; font-weight:650">

  Requirements:<br />
</span>
  <span style="font-size: 0.8rem;">
  - Pursuing or completed a degree in Computer Science, or a related field.<br/>
  - Basic knowledge of frontend development and at least 3 months of practical experience, such as personal projects, or internships.<br/>
  - Familiarity with  HTML, CSS, Bootstrap, JavaScript, ES6, ReactJS.<br/>
  - A fundamental understanding of creating responsive and user-friendly web interfaces.<br/>
  - Willingness to learn and work with version control systems (e.g., Git).<br/>
  - Eagerness to explore modern frontend development practices.<br/>
  - Problem-solving and debugging skills at a foundational level.<br/>
  - Strong communication and teamwork skills, demonstrating the ability to collaborate in a fast-paced environment.<br/>




  </span>
  `;


  const text7 = `
  <span style="font-size: 0.9rem;">

  Are you a motivated and enthusiastic intern with a budding interest in Backend Development? If you possess a basic grasp of Node.js, Express.js, Python and other backend technologies, we welcome you to be part of our dynamic team and embark on an internship journey.
  <br />
  </span>
  <span style="font-size: 0.9rem; font-weight:650">

  Requirements:<br />
</span>
  <span style="font-size: 0.8rem;">
  - Pursuing or completed a degree in Computer Science, or a related field.<br/>

  - Basic knowledge of backend development and at least 3 months of practical experience, such as personal projects, or internships.<br/>
  - Familiarity with Node.js, Python, database and other relevant backend technologies.<br/>
  - A fundamental understanding of RESTful APIs, web security, and backend architecture.<br/>
  - Willingness to learn and work with version control systems (e.g., Git).<br/>
  - Eagerness to explore microservices architecture and containerization.<br/>
  - Problem-solving and debugging skills at a foundational level.<br/>
  - Strong communication and teamwork skills, demonstrating the ability to collaborate in a fast-paced environment.<br/>





  </span>
  `;

  const truncatedText1 = showMore1 ? text1 : text1.slice(0, 200) + "...";
  const truncatedText2 = showMore2 ? text2 : text2.slice(0, 200) + "...";
  const truncatedText3 = showMore3 ? text3 : text3.slice(0, 200) + "...";
  const truncatedText4 = showMore4 ? text4 : text4.slice(0, 200) + "...";
  const truncatedText5 = showMore5 ? text5 : text5.slice(0, 200) + "...";
  const truncatedText6 = showMore6 ? text6 : text6.slice(0, 200) + "...";
  const truncatedText7 = showMore7 ? text7 : text7.slice(0, 200) + "...";



  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "30rem",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingBottom: "5rem",
          backgroundColor: "white",

          "@media(min-width:481px) and (max-width:768px)": {
            paddingBottom: "2rem",
            minHeight: "50vh",
          },
        }}
      >
        <Box sx={{ width: "100%", paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: "600",
              fontSize: "1.5rem",
              color: "#261141",
              "@media(max-width:480px)": {
                fontSize: "1.2rem",
              },
            }}
            className="line"
          >
            Open Vacancies
          </Typography>
        </Box>

{/* ---------------------------------------------UIUX Intern--------------------- */}
<Grid
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >


          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
              UI UX Designer Intern
            </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Lahore</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Nov 7, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore5 ? text5 : truncatedText5 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore5(!showMore5)}
              >
                {showMore5 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/6nGWHApVKWakgSWMA" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "12px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />

{/* ----------------------------------------------Frontend developer intern--------------------- */}

<Grid
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >


          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
              Frontend Developer Intern
            </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Lahore</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Nov 7, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore6 ? text6 : truncatedText6 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore6(!showMore6)}
              >
                {showMore6 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/yTLaA9AUk24HMFy18" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "12px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />

{/* --------------------------------------------backend developer intern--------- */}
<Grid
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >


          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
              Backend Developer Intern
            </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Lahore</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Nov 7, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore7 ? text7 : truncatedText7 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore7(!showMore7)}
              >
                {showMore7 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/JSJ5oTQBSCy4FZS38" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "12px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />

{/* --------------------------------------Sr. UIUX Designer------------- */}
        <Grid
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >


          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
              UI UX Designer
            </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Lahore</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Nov 3, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore1 ? text1 : truncatedText1 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore1(!showMore1)}
              >
                {showMore1 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/yVDZBRnTxb1oHudX6" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "12px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />

        <Grid
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >
          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
              Frontend Developer
            </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Lahore</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Nov 3, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore2 ? text2 : truncatedText2 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore2(!showMore2)}
              >
                {showMore2 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/Kd8PRe1D5FcQLnJ69" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "12px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />

        {/* -------------------------------Backend developer---------------- */}
        <Grid
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >
          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
              Backend developer
            </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Lahore</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Nov 3, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore3 ? text3 : truncatedText3 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore3(!showMore3)}
              >
                {showMore3 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/zn984NYgGbhzFo4X8" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "12px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />

        {/* --------------------------Full Stack developer--------------- */}

        <Grid
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >
          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
              Full Stack Developer
            </Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Lahore</Typography>
            <Typography sx={{ fontSize: "0.9rem" }}>Nov 3, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore4 ? text4 : truncatedText4 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore4(!showMore4)}
              >
                {showMore4 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/jXxueyB6TkkfxP9d9" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "12px",
                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />



        <ApplyFormModal isOpen={isModalOpen} onRequestClose={closeModal} />
      </Box>
    </>
  );
};

export default OpenJobs;

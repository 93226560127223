import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Innovation from "../../Assets/Images/Values/Innovation.svg";
import Satisfection from "../../Assets/Images/Values/satisfection.svg";
import Quality from "../../Assets/Images/Values/Quality.svg";
import Focus from "../../Assets/Images/Values/focus.svg";

const CoreValues = () => {
  const BoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const TypographyHeading = {
    fontSize: "1rem",
    fontWeight: "400",
    color: "white",

    "@media(max-width:480px)": {
      fontSize: "0.9rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      textAlign: "center",
    },
    "@media(min-width:4810px) and (max-width:768px)": {
      fontSize: "1rem",
    },
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "30rem",
          paddingBottom: "5rem",
          backgroundColor: "#261141",
          paddingLeft: "10%",
            paddingRight: "10%",
          "@media(max-width:480px)": {
            paddingLeft: "10%",
            paddingRight: "10%",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            paddingBottom: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",

            "@media(max-width:480px)": {
              paddingTop: "3rem",
            },
            "@media(min-width:481px) and (max-width:768px)": {
              paddingTop: "3rem",
            },
            "@media(min-width:769px) and (max-width:1024px)": {
              paddingTop: "3rem",
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: "600",
              fontSize: "1.8rem",
              color: "white",
              "@media(max-width:480px)": {
                fontSize: "1.3rem",
              },
              "@media(min-width:769px) and (max-width:1024px)": {
                fontSize: "1rem",
              },
            }}
            className="workline"
          >
            Our Philosophy
          </Typography>

          <Typography
            textAlign={"center"}
            width="100%"
            paddingTop={"2rem"}
            color="white"
            sx={{
              fontSize: "1.1rem",
              "@media(max-width:480px)": {
                fontSize: "0.9rem",
                width: "100%",
              },
              "@media(min-width:480px) and (max-width:768px)": {
                fontSize: "1rem",
                width: "100%",

              },
              "@media(min-width:769px) and (max-width:1024px)": {
                fontSize: "0.8rem",
              },
            }}
          >
            Rapid innovation is an essential feature of digital information
            technology. Remcosol Technologies understands the importance of
            remaining current through frequent self-reinvention. We accomplish
            this by continually attracting the brightest minds in modern digital
            paradigms and platforms. By maintaining a roster of skilled
            technology professionals across every business function, Remcosol
            Technologies distinguishes itself by offering its client
            organizations a single touchpoint to address all of their enterprise
            technology needs.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            paddingLeft={"10%"}
            paddingRight={"10%"}
            sx={{
              "@media(max-width:480px)": {
                paddingLeft: "5%",
                paddingRight: "5%",
              },
            }}
          >
            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              sx={{
                "@media(max-width:480px)": {
                  marginTop: "1rem",
                },
              }}
            >
              <Box sx={BoxStyle}>
                <div class="polygon-container">
                  <div class="polygon-wrapper">
                    <img src={Innovation} alt="Inside" />
                  </div>
                </div>

                <Typography variant="h5" sx={TypographyHeading}>
                  Innovation
                </Typography>

                {/* <Typography sx={TypographyContent}>
                  Fueling progress as we tirelessly craft innovations, we're
                  your partners in shaping the future. Together, we forge new
                  paths.
                </Typography> */}
              </Box>
            </Grid>

            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              sx={{
                "@media(max-width:480px)": {
                  marginTop: "1rem",
                },
              }}
            >
              <Box sx={BoxStyle}>
                <div class="polygon-container">
                  <div class="polygon-wrapper">
                    <img src={Quality} alt="Inside" />
                  </div>
                </div>
                <Typography variant="h5" sx={TypographyHeading}>
                  Quality Work
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              sx={{
                "@media(max-width:480px)": {
                  marginTop: "2rem",
                },
              }}
            >
              <Box sx={BoxStyle}>
                <div class="polygon-container">
                  <div class="polygon-wrapper">
                    <img src={Focus} alt="Inside" />
                  </div>
                </div>
                <Typography variant="h5" sx={TypographyHeading}>
                  Client Focus
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              lg={3}
              md={3}
              sm={6}
              xs={6}
              sx={{
                "@media(max-width:480px)": {
                  marginTop: "2rem",
                },
              }}
            >
              <Box sx={BoxStyle}>
                <div class="polygon-container">
                  <div class="polygon-wrapper">
                    <img src={Satisfection} alt="Inside" />
                  </div>
                </div>
                <Typography variant="h5" sx={TypographyHeading}>
                  Customer Satisfection
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CoreValues;
